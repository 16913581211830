import React from 'react';
import useRouter from '@/Framework/hooks/useNextRouter';
import { footerConfig } from './footerConfig';
import { DataroomTenant } from '@/dataroom/domain/vo/types/DataroomTenant';
import StickyFooter from '@/ui/shared/components/Landing/StickyFooter';
import styles from './footer.scss';

interface IProps {
  tenant: DataroomTenant,
}

const Footer = ({ tenant }: IProps) => (
  <StickyFooter
    contentClassName={ styles.Content }
    config={ footerConfig(tenant, useRouter().asPath) }
  />
);

export default Footer;
