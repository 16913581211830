import React from 'react';
import cn from 'classnames';
import useRouter from '@/Framework/hooks/useNextRouter';
import Favicon from './Favicon';
import config from '@/dataroom/application/config/config';
import dataroomUrl from '@/dataroom/infrastructure/dataroomUrl';
import dmPortalUrl from '@/dmPortal/infrastructure/url/dmPortalUrl';
import TenantContext from '@/Framework/Tenant/TenantContext';
import ScrollBodyBackground from '@/ui/shared/components/Layout/ScrollBodyBackground';
import Header from '@/ui/shared/components/Landing/Legacy/Sections/Header';
import BookDemoButton from '@/ui/shared/modules/ScheduleDemo/ScheduleDemoModalLegacy/BookDemoButton';
import CookiesBanner from '@/ui/shared/components/Landing/CookiesBanner';
import Footer from './Footer';
import stickyFooterStyles from '@/ui/shared/components/Landing/StickyFooter/stickyFooter.scss';
import headerStyles from '@/ui/shared/components/Landing/Legacy/Sections/Header/header.scss';
import styles from './tenantInvestorSet.scss';
import logo from '@/dataroom/ui/assets/investorSetLogoText.svg';

interface IProps {
  children: React.ReactNode,
}

const getHeaderMenu = (pathname) => [
  {
    key: 'how-it-works',
    title: 'How it Works',
    path: '/how-it-works',
  },
  {
    key: 'explore-sets',
    title: 'Explore Sets',
    path: '/explore-sets/all',
    isActive: () => pathname.startsWith('/explore-sets'),
  },
  {
    key: 'pricing',
    title: 'Pricing',
    path: '/pricing',
  },
];

const TenantInvestorSet = ({ children }: IProps) => {
  const tenant = config.tenant.tenantInvestorSet.code;
  const { asPath: pathname } = useRouter();

  const loginUrl = dataroomUrl(tenant).getLoginUrl(
    dmPortalUrl.getUrl(`/${ tenant }`),
  );

  const registerUrl = dataroomUrl(tenant).getRegisterUrl(
    dataroomUrl(tenant).getUrl(pathname),
  );

  return (
    <TenantContext tenant={ tenant }>
      <Favicon />
      <Header
        tenant={ tenant }
        logo={ logo }
        menu={ getHeaderMenu(pathname) }
        button={ () => (
          <BookDemoButton className={ headerStyles.menuBtn } />
        ) }
        loginUrl={ loginUrl }
        registerUrl={ registerUrl }
        isNext
      />
      <div className={ cn(styles.pageContainer, stickyFooterStyles.pageContainerWithStickyFooter) }>
        <ScrollBodyBackground tenant={ tenant } />
        { children }
      </div>
      <Footer tenant={ tenant } />
      <CookiesBanner />
    </TenantContext>
  );
};

export default TenantInvestorSet;
